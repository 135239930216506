import { onBeforeMount, type App } from "vue";
import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { createApolloProvider } from "@vue/apollo-option";
import { CONFIG_OPTIONS as config } from "@src/globals";

/** Adds target="_blank" to all a-tags that lead to an external site. */
function addTargetBlankToExternalLinks() {
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
        try {
            let originHost = window.location.hostname;
            let targetHost = new URL(link.href).hostname;
            [originHost, targetHost].forEach((host) => {
                if (host.startsWith("www.")) {
                    host = host.replace("www.", "");
                }
            });
            if (targetHost !== originHost) {
                link.setAttribute("target", "_blank");
                link.setAttribute("rel", "noopener noreferrer");
            }
        } catch (e) {}
    });
}

const setPlaceholderImage = (image: HTMLImageElement) => {
    image.src = `/images/${config.global.placeholderFilename}`;
    image.alt = "Placeholder Image";
    image.style.filter = config.global.placeholderCssFilter;
};

function setImageProperties() {
    const images = document.querySelectorAll("img");
    images.forEach((image) => {
        if (!image.classList.contains("anti-lazy")) {
            image.setAttribute("loading", "lazy");
        } else {
            image.setAttribute("loading", "eager");
        }
        try {
            if (!image.src) {
                setPlaceholderImage(image);
            }

            if (!image.alt) {
                image.alt = "Image without title";
            }

            if (!image.complete) {
                image.style.background = `url('/images/${config.global.placeholderFilename}')`;
                image.style.backgroundSize = "cover";
                image.style.backgroundPosition = "center";
            }

            image.onload = (event) => {
                image.style.background = "none";
                image.style.backgroundSize = "auto";
                image.style.backgroundPosition = "auto";
            };

            image.onerror = () => setPlaceholderImage(image);
        } catch (e) {}
    });
}

function accessibilityHack() {
    const links = document.querySelectorAll("a");
    links.forEach((link) => {
        link.setAttribute(
            "aria-label",
            link.innerText || link.href || "Button/Link",
        );
    });
}

const externalLinksMixin = {
    mounted() {
        addTargetBlankToExternalLinks();
    },
    updated() {
        addTargetBlankToExternalLinks();
    },
};

const imgPropertiesMixin = {
    mounted() {
        setImageProperties();
    },
    updated() {
        setImageProperties();
    },
};

const accessibilityHackMixin = {
    mounted() {
        accessibilityHack();
    },
    updated() {
        accessibilityHack();
    },
};

export default (app: App) => {
    if (import.meta.env.SSR) return;

    let url =
        import.meta.env.PUBLIC_GRAPHQL_URL ?? process.env.PUBLIC_GRAPHQL_URL;

    if (!url) {
        console.error("PUBLIC_GRAPHQL_URL is not defined in .env file");
        return;
    }

    const apolloProvider = createApolloProvider({
        defaultClient: new ApolloClient({
            uri: url,
            cache: new InMemoryCache(),
        }),
    });
    app.mixin(externalLinksMixin);
    app.mixin(imgPropertiesMixin);
    app.mixin(accessibilityHackMixin);
    app.use(apolloProvider);
};
